import { createSlice } from "@reduxjs/toolkit";
import { getStorage, setStorag, delStorage } from "common/Storage/storage";

function initState() {
  let user = getStorage("account-info", "");
  if (typeof user.name === "undefined" || 0 === user.name.length) {
    return false;
  }

  return true;
}

export const userStore = createSlice({
  name: "user",
  initialState: {
    loginState: initState(),
    account: getStorage("account-info", {}),
  },
  reducers: {
    updateUser: (state, action) => {
      let data = action.payload;
      let ur = {
        name: data.data.account.username,
        email: data.data.account.username,
        image: "/logo320.jpeg",
        utl: data.data.account.utk,
      };

      setStorag("account-info", ur);
      setStorag("aic-token", data.data.token);
      state.account = ur;
      state.loginState = true;
    },
    updateState: (state, action) => {
      state.loginState = action.payload;
    },
    logout: (state, action) => {
      state.account = {};
      state.loginState = false;
      delStorage("account-info");
      delStorage("aic-token");
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser, updateState, logout } = userStore.actions;

export default userStore.reducer;
