const config = {
  api: "https://www.chat-service.org",
  ws: "wss://www.chat-service.org",
  platform: {
    image: {
      upload: "https://www.chat-service.org/api/platform/9vr3IRClIvoZiSPBYYUQ",
      download: "https://www.chat-service.org",
    },
  },
  chat: {
    image: {
      upload: "https://www.chat-service.org/api/1b6027209c60/81820d94cc4",
      download: "https://www.chat-service.org",
    },
  },
};

export default config;
