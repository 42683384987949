export function timeAgo(dt) {
  const date = new Date(dt);
  const now = new Date();
  const diffInMilliseconds = now - date;
  const seconds = Math.floor(diffInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return `${seconds}s ago`;
  }
}

export function subString(str, amount) {
  if (typeof str !== "string") {
    return str;
  }
  if (str.length <= amount) {
    return str;
  }

  return str.substring(0, amount) + "...";
}
