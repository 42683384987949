import * as React from "react";
import { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  ButtonBase,
  Tooltip,
  Divider,
  Link,
  FormControlLabel,
  Switch,
  IconButton,
  Collapse,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import {
  OnSdkAccess,
  OnPlatforms,
  OnAddPlatform,
  OnUpdatePlatform,
} from "common/Api";
import { useNavigate } from "react-router-dom";
import { logout } from "common/Reduxer/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  nowPlatform,
  alertMessage,
  setAccess,
} from "common/Reduxer/chat.reducer";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import config from "common/config";
import { Locale } from "common/locale";
import { Input } from "react-chat-elements";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  borderRadius: "5px",
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  borderRadius: "5px",
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  borderRadius: "5px",
  transition: theme.transitions.create("opacity"),
}));

const AddId = 10000009;
const add_platform = {
  id: AddId,
  site: "",
};

function Index(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [platform, setPlatform] = React.useState([]);
  const [expanded, setExpanded] = React.useState(true);
  const nameReferance = React.createRef();
  const welcomeReferance = React.createRef();
  const locale = useSelector((state) => state.localer.locale);

  const [isAdd, setIsAdd] = React.useState(false);
  const now_plat = useSelector((state) => state.chater.current.platform);
  const access = useSelector((state) => state.chater.current.access);
  const [sel, setSel] = React.useState(now_plat);
  const [name, setName] = React.useState(now_plat.name);
  const [site, setSite] = React.useState(now_plat.site);
  const [status, setStatus] = React.useState(now_plat.status !== 1);
  const [icon, setIcon] = React.useState(now_plat.icon);
  const [welcome, setWelcome] = React.useState(now_plat.welcome);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSiteChange = (event) => {
    setSite(event.target.value);
  };

  const handleWelcomeChange = (event) => {
    setWelcome(event.target.value);
  };

  const uploadFile = (file) => {
    const url = config.platform.image.upload;
    const formData = new FormData();
    formData.append("file", file); // 添加文件到 FormData

    // 使用fetch API上传文件
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // 上传成功后的处理
        dispatch(alertMessage({ severity: "success", message: "Success" }));
        let path = data["path"];
        if (!sel.id || "undefined" === typeof sel.id) {
          setIcon(path);
        } else {
          let body = {
            id: sel.id,
            uid: sel.uid,
            name: sel.name,
            site: sel.site,
            icon: path,
            status: sel.status,
          };

          setIcon(path);
          onUpdatePlatform(body);
        }
      })
      .catch((error) => {
        // 上传失败时的处理
        console.error("错误:", error);
        dispatch(alertMessage({ severity: "error", message: "error" }));
      });
  };

  const reqAccess = React.useCallback(
    (site) => {
      OnSdkAccess(site)
        .then((res) => {
          if (res.status !== 200) {
            return;
          }

          setExpanded(true);
          dispatch(setAccess(res.data));
        })
        .catch((err) => {
          if (err.status === 401) {
            dispatch(logout({}));
            navigate("/");
            return;
          }
        });
    },
    [dispatch, navigate],
  );

  const addPlatform = (platform) => {
    OnAddPlatform(platform)
      .then((res) => {
        if (res.status !== 200) {
          dispatch(alertMessage({ severity: "error", message: res.data }));

          return;
        }

        platforms();
        dispatch(alertMessage({ severity: "success", message: res.data }));
      })
      .catch((err) => {
        if (err.status === 401) {
          dispatch(logout({}));
          navigate("/");
          return;
        }
      });
  };

  const platforms = React.useCallback(() => {
    OnPlatforms()
      .then((res) => {
        if (res.status !== 200) {
          return;
        }

        if (0 === res.data.length) {
          setPlatform([add_platform]);

          return;
        }

        res.data.push(add_platform);

        setPlatform(res.data);
        if (!now_plat.id || 0 === now_plat.id) {
          dispatch(nowPlatform(res.data[0]));
          setSel(res.data[0]);
          setName(res.data[0].name);
          setSite(res.data[0].site);
          setIcon(res.data[0].icon);
          setStatus(res.data[0].status);
          setWelcome(res.data[0].welcome);

          reqAccess(res.data[0].site);
        } else {
          res.data.map((it) => {
            if (it.id === now_plat.id) {
              dispatch(nowPlatform(it));
              setSel(it);
              setName(it.name);
              setSite(it.site);
              setIcon(it.icon);
              setStatus(it.status);
              setWelcome(it.welcome);
            }
            return it;
          });
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          // login timeout
          dispatch(logout({}));
          navigate("/");
          return;
        }
      });
  }, [dispatch, navigate, reqAccess]);

  const onUpdatePlatform = React.useCallback(
    (platform) => {
      OnUpdatePlatform(platform)
        .then((res) => {
          if (res.status !== 200) {
            dispatch(alertMessage({ severity: "error", message: res.data }));

            return;
          }

          dispatch(alertMessage({ severity: "success", message: res.data }));

          platforms();
        })
        .catch((err) => {
          if (err.status === 401) {
            dispatch(logout({}));
            navigate("/");
            return;
          }
        });
    },
    [platforms, dispatch, navigate],
  );

  useEffect(() => {
    platforms();
  }, [platforms]);
  return (
    <Container
      width={1}
      height={1}
      sx={{
        minHeight: "256px",
        overflowY: "auto",
        marginTop: "64px",
        border: "1px solid rgba(140,140,140, 0.3)",
        borderRadius: "10px",
      }}
      fixed
    >
      <Box flexGrow={1} mt={4}>
        <Grid container spacing={2}>
          {platform.map((it, index) => (
            <Tooltip title="Click edit" key={it.id}>
              <ImageButton
                focusRipple
                style={{
                  width: "30%",
                  borderRadius: "8px",
                  border: it.id === sel.id ? "3px solid #11ff11" : "",
                }}
                onClick={() => {
                  if (it.id === AddId) {
                    setSel(it);
                    setName("");
                    setIsAdd(true);
                    setStatus(0);
                    setSite("");
                    setIcon("");
                    setWelcome("");
                    setExpanded(false);
                    return;
                  }

                  setSel(it);
                  setName(it.name);
                  setIsAdd(false);
                  setStatus(it.status);
                  setIcon(it.icon);
                  setSite(it.site);
                  setWelcome(it.welcome);

                  reqAccess(it.site);
                  dispatch(nowPlatform(it));
                }}
              >
                <ImageSrc
                  style={{
                    backgroundImage: `url(${it.id === AddId ? "" : "/static/images/platform-bg.jpg"})`,
                  }}
                />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={(theme) => ({
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: `calc(${theme.spacing(1)} + 6px)`,
                    })}
                  >
                    {it.id === AddId ? (
                      ""
                    ) : (
                      <Stack direction={"row"}>
                        <img
                          style={{
                            height: "48px",
                            width: "48px",
                            borderRadius: "48px",
                          }}
                          src={
                            it.icon && it.icon.length > 0
                              ? config.platform.image.download + it.icon
                              : "/logo320.jpeg"
                          }
                          alt=""
                        />{" "}
                        <Box ml={1} sx={{ lineHeight: "48px" }}>
                          {it.name}
                        </Box>{" "}
                      </Stack>
                    )}
                    {it.id === AddId ? <AddIcon fontSize="large" /> : it.site}
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Tooltip>
          ))}
        </Grid>
      </Box>

      <Stack mt={3} mb={8} minWidth={"316px"} direction={"column"}>
        <Divider />
        <Stack width={1} direction={"row"} pt={1}>
          <Box>{Locale[locale].t44}</Box>
          <Box ml={2}>
            <Typography
              component="span"
              fontSize={12}
              pl={1}
              pr={1}
              sx={{
                paddingTop: "2px",
                paddingBottom: "2px",
                color: "rgba(255,255,255,1)",
                backgroundColor: sel.status === 2 ? "#0ee91d" : "#e9520e",
                borderRadius: "8px",
              }}
            >
              {sel.status === 2 ? Locale[locale].t57 : Locale[locale].t58}
            </Typography>
          </Box>
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              disabled={isAdd}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography
            padding={1}
            sx={{
              color: "#ffffff",
              borderRadius: 1,
              backgroundColor: "rgba(0,0,0,0.8)",
            }}
          >
            {access}
          </Typography>
        </Collapse>
        <Box height={expanded ? "24px" : "0px"}></Box>
        <Divider />
        <Stack width={1} direction={"row"} p={1}>
          <Box sx={{ width: "50%" }}>{Locale[locale].t45}:</Box>
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            <Input
              referance={nameReferance}
              placeholder="Type here..."
              value={name}
              maxlength={300}
              autoHeight={true}
              maxHeight={50}
              onChange={handleNameChange}
              rightButtons={
                <IconButton
                  sx={{
                    display: isAdd ? "none" : "flex",
                  }}
                  aria-label="toggle password visibility"
                  onClick={() => {
                    if (name === sel.name) {
                      return;
                    }

                    let body = {
                      id: sel.id,
                      uid: sel.uid,
                      name: name,
                      site: sel.site,
                      icon: sel.icon,
                      status: sel.status,
                      welcome: sel.welcome,
                    };
                    onUpdatePlatform(body);
                  }}
                  disabled={name === sel.name}
                >
                  <CheckIcon
                    fontSize="small"
                    color={name === sel.name ? "" : "success"}
                  />
                </IconButton>
              }
            />
          </Box>
        </Stack>
        <Divider />
        <Stack width={1} direction={"row"} p={1}>
          <Box sx={{ width: "50%" }}>{Locale[locale].t46}:</Box>
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            {isAdd ? (
              <Input
                inputStyle={{
                  width: "100%",
                }}
                placeholder="Input platform site..."
                value={site}
                maxlength={300}
                autoHeight={true}
                onMaxLengthExceed={() => {}}
                maxHeight={50}
                onChange={handleSiteChange}
                rightButtons={<></>}
              />
            ) : (
              <Link href={sel.site}>{sel.site}</Link>
            )}
          </Box>
        </Stack>
        <Divider />
        <Stack width={1} direction={"row"} p={1}>
          <Box sx={{ width: "50%" }}>Welcome:</Box>
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            <Input
              inputStyle={{
                width: "100%",
              }}
              referance={welcomeReferance}
              placeholder="Input platform welcome word..."
              value={welcome}
              maxlength={300}
              autoHeight={true}
              onMaxLengthExceed={() => {}}
              maxHeight={50}
              onChange={handleWelcomeChange}
              rightButtons={
                <IconButton
                  sx={{
                    display: isAdd ? "none" : "flex",
                  }}
                  aria-label="toggle password visibility"
                  onClick={() => {
                    let body = {
                      id: sel.id,
                      uid: sel.uid,
                      name: sel.name,
                      site: sel.site,
                      icon: sel.icon,
                      status: sel.status,
                      welcome: welcome,
                    };
                    onUpdatePlatform(body);
                  }}
                  disabled={welcome && welcome.length === 0}
                >
                  <CheckIcon
                    fontSize="small"
                    color={welcome && welcome.length === 0 ? "" : "success"}
                  />
                </IconButton>
              }
            />
          </Box>
        </Stack>
        <Divider />
        <Stack width={1} direction={"row"} p={1}>
          <Box sx={{ width: "50%" }}>{Locale[locale].t47}:</Box>
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    setStatus(e.target.checked ? 0 : 1);
                    let body = {
                      id: sel.id,
                      uid: sel.uid,
                      name: sel.name,
                      site: sel.site,
                      icon: sel.icon,
                      status: e.target.checked ? 0 : 1,
                      welcome: sel.welcome,
                    };
                    onUpdatePlatform(body);
                  }}
                  sx={{ marginRight: "-15px" }}
                  checked={status !== 1}
                />
              }
              label=""
            />
          </Box>
        </Stack>
        <Divider />
        <Stack width={1} direction={"row"} p={1}>
          <Box sx={{ lineHeight: "48px", width: "50%" }}>
            {Locale[locale].t48}:
          </Box>
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() => {
                const fileInput = document.getElementById("file-input");
                fileInput.click();
              }}
            >
              <img
                style={{
                  height: "48px",
                  width: "48px",
                  borderRadius: "48px",
                }}
                src={
                  icon && icon.length > 0
                    ? config.platform.image.download + icon
                    : "/logo320.jpeg"
                }
                alt={icon}
              />
            </IconButton>
          </Box>
        </Stack>
        <Divider />
        <Stack
          mt={3}
          sx={{
            display: isAdd ? "flex" : "none",
          }}
        >
          <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                if (site.length === 0 || name.length === 0) {
                  return;
                }
                let body = {
                  site: site,
                  name: name,
                  status: status,
                  icon: icon,
                  welcome: welcome,
                };
                addPlatform(body);
              }}
            >
              {Locale[locale].t49}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <input
        type="file"
        id="file-input"
        accept="image/*"
        onChange={() => {
          const fileInput = document.getElementById("file-input");
          const file = fileInput.files[0]; // 获取第一个文件
          if (file) {
            uploadFile(file); // 调用上传文件函数
          }
        }}
        style={{
          display: "none",
        }}
      />
    </Container>
  );
}

Index.propTypes = {};
export default Index;
