import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Typography,
  ListItemText,
  Tooltip,
  Badge,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { MessageBox, Input } from "react-chat-elements";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import AddLinkIcon from "@mui/icons-material/AddLink";
import SendIcon from "@mui/icons-material/Send";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import config from "common/config";

import { nowChat, badgeChat } from "common/Reduxer/chat.reducer";
import { timeAgo, subString } from "common";
import {
  deepOrange,
  deepPurple,
  lightGreen,
  lightBlue,
} from "@mui/material/colors";

function getAvatarColor(username) {
  if (typeof username !== "string" || !username || username.length === 0) {
    return lightGreen[500];
  }

  let fcode = username.substring(0, 1).toLowerCase();
  fcode = fcode.charCodeAt(0);
  if (fcode >= "a".charCodeAt(0) && fcode <= "h".charCodeAt(0)) {
    return deepOrange[500];
  }

  if (fcode >= "i".charCodeAt(0) && fcode <= "p".charCodeAt(0)) {
    return lightBlue[500];
  }

  if (fcode >= "q".charCodeAt(0) && fcode <= "z".charCodeAt(0)) {
    return deepPurple[500];
  }

  return lightGreen[500];
}

function Index(props) {
  const { sendMessage } = props;
  const dispatch = useDispatch();
  const displayDiv = document.getElementById("chat-image-display-div");
  const imageDisplay = document.getElementById("chat-image-display");
  const chatlist = useSelector((state) => state.chater.list);
  const chat = useSelector((state) => state.chater.current.chat);
  const message = useSelector((state) => state.chater.current.message);
  const now_plat = useSelector((state) => state.chater.current.platform);

  const inputReferance = React.createRef();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChatListClick = (event, chat) => {
    dispatch(nowChat(chat));
    dispatch(badgeChat(0));
  };

  useEffect(() => {
    if (!chat || !chat.username || 0 === chat.username.length) {
      return;
    }

    sendMessage(
      JSON.stringify({
        proto: 2,
        body: { username: chat.username },
        code: 200,
      }),
    );
  }, [chat, sendMessage]);

  useEffect(() => {
    const component = document.getElementById("message-list-id");
    component.scrollTop = component.scrollHeight;
  }, [message]);

  const uploadFile = (file) => {
    const url = config.chat.image.upload;
    const formData = new FormData();
    formData.append("file", file); // 添加文件到 FormData

    // 使用fetch API上传文件
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // 上传成功后的处理
        let path = data["path"];
        sendMessage(
          JSON.stringify({
            proto: 0x0001,
            body: {
              type: 1,
              username: chat.username,
              message: path,
            },
            code: 200,
          }),
        );

        inputReferance.current.value = "";
        document.getElementById("file-input").value = "";
      })
      .catch((error) => {
        // 上传失败时的处理
        console.error("错误:", error);
        inputReferance.current.value = "";
      });
  };

  return (
    <Box
      width={1}
      display={"flex"}
      height={1}
      paddingLeft={2}
      paddingRight={2}
      sx={{
        borderRadius: "4px",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          borderRight: "1px solid #e5e5e5",
        }}
      >
        <List
          sx={{
            minHeight: "480px",
            minWidth: "256px",
            maxHeight: "720px",
            overflowY: "auto",
          }}
          marginBottom={"15px"}
        >
          {chatlist.map((it) => {
            return (
              <>
                <ListItemButton
                  onClick={(e) => handleChatListClick(e, it)}
                  selected={it.id === chat.id}
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{ bgcolor: getAvatarColor(it.username) }}
                      alt={it.username}
                      src={"/static/images/" + it.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box display={"flex"}>
                        <Badge badgeContent={parseInt(it.unread)} color="error">
                          <Typography
                            flexGrow={1}
                            component="span"
                            variant="subtitle2"
                            sx={{ color: "text.primary", display: "inline" }}
                          >
                            {subString(it.title, 16)}
                          </Typography>
                        </Badge>
                      </Box>
                    }
                    secondary={
                      <Stack direction={"column"}>
                        <Stack direction={"row"}>
                          <Typography
                            flexGrow={1}
                            component="span"
                            variant="overline"
                            sx={{
                              color: "rgba(0,0,0,0.6)",
                              display: "inline",
                            }}
                          >
                            {subString(it.subtitle, 12)}
                          </Typography>
                          <Typography
                            component="span"
                            variant="subtitle2"
                            fontSize={12}
                            sx={{
                              color: "rgba(0,0,0,0.6)",
                              display: "inline",
                              marginTop: "8px",
                            }}
                          >
                            {timeAgo(it.date)}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"}>
                          <Typography
                            component="span"
                            fontSize={12}
                            sx={{
                              color: "rgba(255,255,255,1)",
                              backgroundColor: "rgba(30,100,10,1)",
                              borderRadius: "8px",
                              cursor: "pointer",
                              paddingLeft: "4px",
                              paddingRight: "4px",
                            }}
                            onClick={() => {
                              let site = it.site;
                              if (!site.startsWith("http")) {
                                site = "http://" + site;
                              }
                              window.open(site, "_blank");
                            }}
                          >
                            {it.site}
                          </Typography>
                          <Box flexGrow={1}></Box>
                        </Stack>
                      </Stack>
                    }
                  />
                </ListItemButton>
              </>
            );
          })}
        </List>
      </Box>

      <Stack display={"flex"} spacing={2} height={1} width={1}>
        <Box
          id="message-list-id"
          height={1}
          width={1}
          padding={2}
          sx={{ overflowY: "auto", backgroundColor: "rgba(140,140,140,0.1)" }}
        >
          {message.map((it) => {
            let msg = JSON.parse(it.message);
            return (
              <Stack
                width={1}
                key={it.id}
                marginTop={"8px"}
                direction={it.source === 1 ? "row-reverse" : "row"}
              >
                <Avatar
                  sx={{
                    bgcolor: getAvatarColor(it.userName),
                    height: 24,
                    width: 24,
                  }}
                  alt={it.userName}
                  src={
                    it.source === 1
                      ? now_plat.icon && now_plat.icon.length > 0
                        ? config.platform.image.download + now_plat.icon
                        : "/logo320.jpeg"
                      : "/static/images/" + it.icon
                  }
                />
                <MessageBox
                  styles={{
                    maxWidth: "512px",
                  }}
                  key={"msg" + it.id}
                  position={it.source === 1 ? "right" : "left"}
                  type={msg.type === 0 ? "text" : "photo"}
                  text={msg.type === 0 ? msg.message : ""}
                  onClick={(e) => {
                    if (0 === msg.type) {
                      return;
                    }

                    displayDiv.style.display = "flex";
                    imageDisplay.src = e.target.src;
                  }}
                  data={{
                    uri: config.chat.image.download + msg.message,
                    status: {
                      download: true,
                      click: false,
                    },
                  }}
                  date={it.createAt}
                />
              </Stack>
            );
          })}
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #e5e5e5",
            borderBottom: "1px solid #e5e5e5",
          }}
          marginTop={2}
          width={1}
        >
          <input
            type="file"
            id="file-input"
            accept="image/*"
            onChange={() => {
              const fileInput = document.getElementById("file-input");
              const file = fileInput.files[0]; // 获取第一个文件
              if (file) {
                uploadFile(file); // 调用上传文件函数
              }
            }}
            style={{
              display: "none",
            }}
          />
          <Input
            referance={inputReferance}
            placeholder="Please input..."
            value={inputValue}
            multiline={true}
            maxlength={300}
            autoHeight={true}
            onMaxLengthExceed={() => {}}
            maxHeight={50}
            onChange={handleInputChange}
            onPaste={(e) => {
              const items = e.clipboardData.items;
              for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf("image") !== -1) {
                  const blob = items[i].getAsFile();

                  const file = new File([blob], "tmp-chat-clip.png", {
                    type: blob.type,
                  });
                  uploadFile(file);
                }
              }
            }}
            onKeyPress={(e) => {
              // if (e.ctrlKey && e.key === "Enter") {
              //   return;
              // }
              if (inputValue.trim().length === 0) {
                return;
              }

              // keypress enter send message
              if (!e.ctrlKey && e.key === "Enter") {
                props.sendMessage(
                  JSON.stringify({
                    proto: 1,
                    body: {
                      type: 0,
                      username: chat.username,
                      message: inputValue,
                    },
                    code: 200,
                  }),
                );

                setInputValue("");
                inputReferance.current.value = "";
              }
            }}
            rightButtons={
              <IconButton
                onClick={(e) => {
                  if (inputValue.length === 0) {
                    const fileInput = document.getElementById("file-input");
                    fileInput.click();

                    return;
                  }
                  props.sendMessage(
                    JSON.stringify({
                      proto: 1,
                      body: {
                        type: 0,
                        username: chat.username,
                        message: inputValue,
                      },
                      code: 200,
                    }),
                  );

                  setInputValue("");
                  inputReferance.current.value = "";
                }}
              >
                {inputValue.length === 0 ? (
                  <AddLinkIcon />
                ) : (
                  <SendIcon color="success" />
                )}
              </IconButton>
            }
          />
        </Box>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        height={1}
        sx={{
          borderLeft: "1px solid #e5e5e5",
          alignItems: "center",
          width: "96px",
        }}
      >
        <Tooltip title="Platform info">
          <IconButton onClick={() => {}}>
            <Avatar
              sx={{ border: "2px solid #e5e5e5" }}
              alt={chat ? chat.username : ""}
              src={
                now_plat.icon && now_plat.icon.length > 0
                  ? config.platform.image.download + now_plat.icon
                  : "/logo320.jpeg"
              }
            />
          </IconButton>
        </Tooltip>
        <IconButton onClick={() => {}}>
          <AddIcon color="success" />
        </IconButton>
        <IconButton onClick={() => {}}>
          <HistoryIcon color="warning" />
        </IconButton>
        <Tooltip title="Clean chat message">
          <IconButton
            onClick={() => {
              props.sendMessage(
                JSON.stringify({
                  proto: 14,
                  body: {
                    username: chat.username,
                  },
                  code: 200,
                }),
              );
            }}
          >
            <CleaningServicesIcon color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}

Index.propTypes = {};
export default Index;
