import axios from "axios";
import config from "common/config";

let baseURL = `${config.api}/api`;

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api的base_url
  timeout: 0, // 请求超时时间
  withCredentials: false,
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("aic-token");
    if (token) {
      config.headers["Authorization"] = `${token.toString()}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default service;
