import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
  SwipeableDrawer,
  TextField,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Badge,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import Chat from "views/Chat";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import HomeIcon from "@mui/icons-material/Home";
import useWebSocket from "react-use-websocket";
import { useSelector, useDispatch } from "react-redux";
import {
  chatList,
  nowMessage,
  nowChat,
  addMessage,
  alertClose,
  badgeChat,
  alertMessage,
} from "common/Reduxer/chat.reducer";
import { logout } from "common/Reduxer/user.reducer";
import Home from "views/Home";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { languages, Locale } from "common/locale";
import { onlocale } from "common/Reduxer/locale.reducer";
import { OnSearch } from "common/Api";
import MessageIcon from "@mui/icons-material/Message";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { timeAgo } from "common";
import config from "common/config";

function Search() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("all");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorLocale, setAnchorLocale] = React.useState(null);
  const locale = useSelector((state) => state.localer.locale);
  const openLocale = Boolean(anchorLocale);
  const handleLocaleClick = (event) => {
    setAnchorLocale(event.currentTarget);
  };
  const handleLocaleClose = () => {
    setAnchorLocale(null);
  };

  const [searchValue, setSearchValue] = React.useState("");
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const defaultLocale = () => {
    for (let i = 0; i < languages.length; ++i) {
      let it = languages[i];
      if (it.code === locale) {
        return it.name;
      }
    }
  };
  const [search, setSearch] = React.useState([]);

  return (
    <React.Fragment>
      <Tooltip title="Search" enterDelay={1000}>
        <IconButton
          type="button"
          aria-label="search"
          onClick={() => {
            setOpen(true);
          }}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Button
        id="fade-button"
        aria-controls={openLocale ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openLocale ? "true" : undefined}
        onClick={handleLocaleClick}
        color="white"
      >
        {defaultLocale()}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorLocale}
        open={openLocale}
        onClose={handleLocaleClose}
        TransitionComponent={Fade}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => {
              dispatch(onlocale(language.code));
              handleLocaleClose();
            }}
          >
            {language.name}
          </MenuItem>
        ))}
      </Menu>
      <SwipeableDrawer
        open={open}
        anchor={"right"}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        sx={{
          zIndex: 99999,
        }}
      >
        <Stack direction={"column"}>
          <Box display={"flex"} pr={1}>
            <Typography variant="body1" pl={1} pt={1}>
              {Locale[locale].t59}
            </Typography>
            <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon color="error" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box pl={2} pt={1} pr={2}>
            <TextField
              label={Locale[locale].t60}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleInputChange}
              slotProps={{
                input: {
                  endAdornment: (
                    <IconButton
                      type="button"
                      aria-label="search"
                      size="small"
                      disabled={searchValue.length < 4}
                      color={searchValue.length > 3 ? "error" : ""}
                      onClick={() => {
                        OnSearch(`keyword=${searchValue}&style=${value}`)
                          .then((res) => {
                            if (res.status !== 200) {
                              return;
                            }
                            setSearch(res.data);
                          })
                          .catch((err) => {
                            if (err.status === 401) {
                              dispatch(logout({}));
                              navigate("/");
                              return;
                            }
                          });
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                  sx: { pr: 0.5 },
                },
              }}
              sx={{ display: { xs: "none", md: "inline-block" }, mr: 2 }}
            />
          </Box>
          <Box pl={2} pr={2} mt={1}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <Stack direction={"row"}>
                  <FormControlLabel
                    value="username"
                    control={<Radio color="error" size="small" />}
                    label={Locale[locale].t61}
                  />
                  <FormControlLabel
                    value="message"
                    control={<Radio color="error" size="small" />}
                    label={Locale[locale].t62}
                  />
                  <FormControlLabel
                    value="all"
                    control={<Radio color="error" size="small" />}
                    label={Locale[locale].t63}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Box>
          <Stack mt={2} pl={2} pr={2} id="search-result-id">
            {search.map((it, index) => {
              let startStr = "";
              let endStr = "";
              let keyword = searchValue;
              let timeAt = it.value.createAt;
              if ("message" === it.style) {
                let message = JSON.parse(it.value.message).message;
                let index = message.indexOf(keyword);
                startStr = message.substring(0, index);
                endStr = message.substring(
                  index + keyword.length,
                  message.length,
                );
              }
              if ("username" === it.style) {
                let message = it.value.account;
                let index = message.indexOf(keyword);
                startStr = message.substring(0, index);
                endStr = message.substring(
                  index + keyword.length,
                  message.length,
                );
              }
              return (
                <Box key={index} width={1} mt={1}>
                  <Stack direction={"row"}>
                    <Box pl={1} pr={1}>
                      {it.style === "message" ? (
                        <MessageIcon fontSize="small" color="success" />
                      ) : (
                        <AccountCircleIcon fontSize="small" color="error" />
                      )}
                    </Box>
                    <Box
                      flexGrow={1}
                      display={"flex"}
                      justifyContent={"flex-start"}
                    >
                      <Typography>{startStr}</Typography>
                      <Typography color="error">{keyword}</Typography>
                      <Typography>{endStr}</Typography>
                    </Box>
                  </Stack>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    lineHeight={2}
                    pl={1}
                    pr={2}
                  >
                    <img
                      style={{
                        height: "18px",
                        width: "18px",
                        lineHeight: "18px",
                      }}
                      src="/static/images/site.png"
                      alt={it.value.site}
                    />
                    <Typography
                      component="span"
                      variant="subtitle2"
                      fontSize={12}
                      sx={{
                        color: "rgba(0,0,0,0.6)",
                        display: "inline",
                        lineHeight: "18px",
                      }}
                    >
                      {it.value.site}
                    </Typography>
                    <Box flexGrow={1}></Box>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      fontSize={12}
                      sx={{
                        color: "rgba(0,0,0,0.6)",
                        display: "inline",
                        lineHeight: "18px",
                      }}
                    >
                      {timeAgo(timeAt)}
                    </Typography>
                  </Box>
                  <Divider />
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

const indexTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  // colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function PageContent({ pathname, sendMessage }) {
  if (pathname === "/home") {
    return <Home sendMessage={sendMessage} />;
  }
  if (pathname === "/chat") {
    return <Chat sendMessage={sendMessage} />;
  }

  if (pathname === "/team") {
    return (
      <Box
        sx={{
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
        height={"100%"}
      >
        {pathname}
      </Box>
    );
  }

  if (pathname === "/") {
    document.location.href = "/";
  }

  return <div>Other {pathname}</div>;
}

PageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function Dashboard(props) {
  const { window } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chat = useSelector((state) => state.chater.current.chat);
  const platform = useSelector((state) => state.chater.current.platform);
  const locale = useSelector((state) => state.localer.locale);
  const account = useSelector((state) => state.user.account);
  const alert = useSelector((state) => state.chater.alert);
  const badge_chat = useSelector((state) => state.chater.badge.chat);
  const [pathname, setPathname] = React.useState("/home");

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);
  const [session] = React.useState({
    user: account,
  });

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        // setSession({
        //   user: {
        //     name: "Bharat Kashyap",
        //     email: "bharatkashyap@outlook.com",
        //     image: "/logo320.jpeg",
        //   },
        // });
      },
      signOut: () => {
        dispatch(logout({}));
        navigate("/");
      },
    };
  }, [navigate, dispatch]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;
  const { sendMessage, lastMessage } = useWebSocket(
    `${config.ws}/fed5b0bac772a7086ab87e9a181d2467?utk=${account.utl}&plt=${platform.site}`,
    {
      onOpen: () => {},
      shouldReconnect: (closeEvent) => true,
    },
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const packet = JSON.parse(lastMessage.data);
      const proto = packet["proto"];
      const body = packet.body;
      // const code = packet.code;
      switch (proto) {
        case 0x0000:
          break;
        case 0x0001:
          dispatch(addMessage(body));
          dispatch(badgeChat(1));

          break;
        case 0x0002:
          dispatch(nowMessage(body));

          break;
        case 0x0003:
          break;
        case 0x0004:
          let payload = JSON.parse(body);
          if (
            (Array.isArray(payload) && (!chat || !chat.username)) ||
            0 === chat.username.length
          ) {
            let it = payload[0];
            dispatch(nowChat(it));
          }

          dispatch(chatList(payload));

          break;
        case 0x0005:
          break;
        case 0x0006:
          break;
        case 0x0007:
          sendMessage(JSON.stringify({ proto: 4, body: {}, code: 200 }));

          break;
        case 0x0008:
          break;
        case 0x000a:
          break;
        case 0x000b:
          break;
        case 0x000c:
          break;
        case 0x000d:
          break;
        case 0x000e:
          dispatch(nowMessage([]));
          dispatch(alertMessage({ severity: "success", message: "Success" }));

          break;
        case 0x9996:
          sendMessage(JSON.stringify({ proto: 4, body: {}, code: 200 }));

          break;
        case 0x9997:
          break;
        case 0x9998:
          break;
        case 0x9999:
          break;
        case 0x10000:
          break;
        default:
          break;
      }
    }
  }, [lastMessage, dispatch, sendMessage]);

  return (
    // preview-start
    <AppProvider
      session={session}
      authentication={authentication}
      navigation={[
        {
          segment: "home",
          title: Locale[locale].t50,
          icon: <HomeIcon />,
        },
        {
          segment: "chat",
          title: Locale[locale].t51,
          icon: (
            <Badge badgeContent={badge_chat} color="error">
              <QuestionAnswerIcon />
            </Badge>
          ),
        },
        // {
        //   segment: "team",
        //   title: Locale[locale].t52,
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   segment: "setting",
        //   title: Locale[locale].t53,
        //   icon: <SettingsApplicationsIcon />,
        // },
      ]}
      branding={{
        logo: (
          <img
            src="/logo320.jpeg"
            style={{ borderRadius: "32px" }}
            alt="CHAT logo"
          />
        ),
        title: Locale[locale].t1,
      }}
      router={router}
      theme={indexTheme}
      window={demoWindow}
    >
      <DashboardLayout
        disableCollapsibleSidebar={false}
        slots={{ toolbarActions: Search }}
      >
        <Box
          height={1}
          width={1}
          sx={{
            backgroundColor: "#ffffff",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "720px",
          }}
        >
          <PageContent pathname={pathname} sendMessage={sendMessage} />
        </Box>
      </DashboardLayout>
      <Snackbar
        open={alert.open}
        onClose={() => {
          dispatch(alertClose());
        }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={alert.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </AppProvider>
    // preview-end
  );
}

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Dashboard;
