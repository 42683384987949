import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Typography from "components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppAppBar from "./modules/views/AppAppBar";
import AppForm from "./modules/views/AppForm";
import { email, required } from "./modules/form/validation";
import RFTextField from "./modules/form/RFTextField";
import FormButton from "./modules/form/FormButton";
import FormFeedback from "./modules/form/FormFeedback";
import withRoot from "./modules/withRoot";
import { OnLogin } from "common/Api";
import { useNavigate } from "react-router-dom";
import { updateUser, updateState } from "common/Reduxer/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Locale } from "common/locale";

function SignIn(props) {
  const [sent, setSent] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const locale = useSelector((state) => state.localer.locale);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = required(["email", "password"], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = (values) => {
    setSent(true);
    // 注册
    let body = { username: values.email, password: values.password };
    OnLogin(body)
      .then((res) => {
        let data = res.data;
        if (data.code !== 0) {
          setSent(false);
          setOpen(true);
          setMessage(Locale[locale][data.message]);

          return;
        }

        dispatch(updateUser(data));
        dispatch(updateState(true));
        navigate("/chat");
      })
      .catch((err) => {
        setSent(false);
      });
  };

  return (
    <React.Fragment>
      <Box sx={{ overflowY: "auto" }}>
        <AppAppBar />
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              {Locale[locale].t41}
            </Typography>
            {/* <Typography variant="body2" align="center">
              {"Not a member yet? "}
              <Link
                href="/chtsys/3474c627769b0ee7"
                align="center"
                underline="always"
              >
                Sign Up here
              </Link>
            </Typography> */}
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 6 }}
              >
                <Field
                  autoComplete="email"
                  autoFocus
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                />
                <Field
                  fullWidth
                  size="large"
                  component={RFTextField}
                  disabled={submitting || sent}
                  required
                  name="password"
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  margin="normal"
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <Typography sx={{ color: "red" }}>
                  {message && message.length > 0 ? message : ""}
                </Typography>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? "In progress…" : "Sign In"}
                </FormButton>
              </Box>
            )}
          </Form>
          <Typography align="center">
            <Link underline="always" href="/chtsys/forgot-password">
              {Locale[locale].t42}
            </Link>
          </Typography>
        </AppForm>
        <AppFooter />
        <Snackbar
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          autoHideDuration={1000}
          message={message}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        />
      </Box>
    </React.Fragment>
  );
}

export default withRoot(SignIn);
