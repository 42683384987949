const languages = [
  {
    code: "en",
    name: "EN",
  },
  {
    code: "vi",
    name: "VNM",
  },
  {
    code: "cn",
    name: "中文",
  },
];

const Locale = {
  en: {
    t1: "Easy Customer",
    t2: "Account info",
    t3: "Start Use",
    t4: "Sign In",
    t5: "Your Website",
    t6: "Legal",
    t7: "Terms",
    t8: "Privacy",
    t9: "Language",
    t10: "Freepik",
    t11: "Snorkeling",
    t12: "Massage",
    t13: "Hiking",
    t14: "Tour",
    t15: "Gastronomy",
    t16: "Shopping",
    t17: "Walking",
    t18: "Fitness",
    t19: "Reading",
    t20: "For all tastes and all desires",
    t21: "Receive offers",
    t22: "Taste the holidays of the everyday close to home.",
    t23: "Your email",
    t24: "Keep me updated",
    t25: "We will send you our best offers, once a week.",
    t26: "Upgrade your website",
    t27: "Research shows that websites with customer service can increase their credibility by 70%",
    t28: "and increase their user base by 10%.",
    t29: "Start use",
    t30: "Explore User Experience",
    t31: "How it works",
    t32: "Appointment every Wednesday 9am.",
    t33: "First come, first served. Our offers are in limited quantities, so be quick.",
    t34: "Get started",
    t35: "Got any questions? Need help?",
    t36: " We are here to help. Get in touch!",
    t37: "The best luxury hotels",
    t38: "New experiences",
    t39: "Exclusive rates",
    t40: "Privacy",
    t41: "Sign In",
    t42: "Forgot password?",
    t43: "Terms",
    t44: "Click to expand access",
    t45: "Alias ​​(displayed by the user)",
    t46: "WebSite",
    t47: "Is it enabled?",
    t48: "Display Icon",
    t49: "Add",
    t50: "Home",
    t51: "Chat",
    t52: "Team",
    t53: "Setting",
    t54: "Icons made by ",
    t55: " from ",
    t56: " is licensed by ",
    t57: "Accessed",
    t58: "Not Accessed",
    t59: "Search",
    t60: "Please enter",
    t61: "User",
    t62: "Message",
    t63: "All",
    t64: "Account not exists",
    t65: "Password error!",
  },
  vi: {
    t1: "Khách hàng dễ tính",
    t2: "Thông tin tài khoản",
    t3: "Bắt đầu sử dụng",
    t4: "Đăng nhập",
    t5: "Trang web của bạn",
    t6: "Pháp lý",
    t7: "Điều khoản",
    t8: "Quyền riêng tư",
    t9: "Ngôn ngữ",
    t10: "Freepik",
    t11: "Lặn với ống thở",
    t12: "Massage",
    t13: "Đi bộ đường dài",
    t14: "Tour",
    t15: "Ẩm thực",
    t16: "Mua sắm",
    t17: "Đi bộ",
    t18: "Thể dục",
    t19: "Đọc sách",
    t20: "Dành cho mọi sở thích và mọi mong muốn",
    t21: "Nhận ưu đãi",
    t22: "Thưởng thức những ngày lễ của cuộc sống thường ngày gần nhà",
    t23: "Email của bạn",
    t24: "Giữ cho tôi cập nhật",
    t25: "Chúng tôi sẽ gửi cho bạn những ưu đãi tốt nhất của chúng tôi, một lần một tuần.",
    t26: "Nâng cấp trang web của bạn",
    t27: "Nghiên cứu cho thấy các trang web có dịch vụ chăm sóc khách hàng có thể tăng độ tin cậy của họ lên 70%",
    t28: "và tăng lượng người dùng của họ lên 10%.",
    t29: "Bắt đầu sử dụng",
    t30: "Khám phá Trải nghiệm người dùng",
    t31: "Cách thức hoạt động",
    t32: "Hẹn gặp vào thứ Tư hàng tuần lúc 9 giờ sáng.",
    t33: "Ai đến trước được phục vụ trước. Ưu đãi của chúng tôi có số lượng hạn chế, vì vậy hãy nhanh tay.",
    t34: "Bắt đầu",
    t35: "Bạn có thắc mắc nào không? Cần trợ giúp không?",
    t36: "Chúng tôi ở đây để trợ giúp. Hãy liên lạc!",
    t37: "Những khách sạn sang trọng nhất",
    t38: "Trải nghiệm mới",
    t39: "Giá độc quyền",
    t40: "Quyền riêng tư",
    t41: "Đăng nhập",
    t42: "Quên mật khẩu?",
    t43: "Điều khoản",
    t44: "Nhấp để mở rộng quyền truy cập",
    t45: "Bí danh (hiển thị trên máy khách)",
    t46: "Trang web",
    t47: "Đã bật chưa?",
    t48: "Biểu tượng hiển thị",
    t49: "Thêm",
    t50: "Trang chủ",
    t51: "Trò chuyện",
    t52: "Nhóm",
    t53: "Cài đặt",
    t54: "Biểu tượng được tạo bởi ",
    t55: "từ ",
    t56: "được cấp phép bởi ",
    t57: "Đã kết nối",
    t58: "Không kết nối",
    t59: "Tìm kiếm",
    t60: "Xin hãy nhập",
    t61: "người dùng",
    t62: "Tin nhắn",
    t63: "tất cả",
    t64: "Tài khoản không tồn tại",
    t65: "Lỗi mật khẩu!",
  },
  cn: {
    t1: "简单客服",
    t2: "帐户信息",
    t3: "开始使用",
    t4: "登录",
    t5: "您的网站",
    t6: "法律",
    t7: "条款",
    t8: "隐私",
    t9: "语言",
    t10: "Freepik",
    t11: "浮潜",
    t12: "按摩",
    t13: "徒步旅行",
    t14: "旅游",
    t15: "美食",
    t16: "购物",
    t17: "散步",
    t18: "健身",
    t19: "阅读",
    t20: "满足所有口味和所有愿望",
    t21: "接收优惠",
    t22: "在家附近品尝日常假期。",
    t23: "您的电子邮件",
    t24: "让我了解最新情况",
    t25: "我们将每周向您发送一次我们的最佳优惠。",
    t26: "升级您的网站",
    t27: "研究表明,拥有客户服务的网站可以提高 70% 的可信度",
    t28: "并将其用户群增加 10%。",
    t29: "开始使用",
    t30: "探索用户体验",
    t31: "工作原理",
    t32: "每周三上午 9 点预约。",
    t33: "先到先得。我们的优惠数量有限,所以请尽快。",
    t34: "开始",
    t35: "有任何问题吗？需要帮助吗？",
    t36: "我们随时为您提供帮助。取得联系！",
    t37: "最佳豪华酒店",
    t38: "全新体验",
    t39: "专属价格",
    t40: "隐私",
    t41: "登录",
    t42: "忘记密码？",
    t43: "条款",
    t44: "点击扩展访问权限",
    t45: "别名（用户显示）",
    t46: "网站",
    t47: "是否已启用？",
    t48: "显示图标",
    t49: "添加",
    t50: "主页",
    t51: "聊天",
    t52: "团队",
    t53: "设置",
    t54: "图标由 制作",
    t55: "来自 ",
    t56: "由 授权",
    t57: "已接入",
    t58: "未接入",
    t59: "搜索",
    t60: "请输入",
    t61: "用户",
    t62: "消息",
    t63: "所有",
    t64: "账户不存在",
    t65: "密码错误！",
  },
};

export { Locale, languages };
