import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "components/Typography";
import { Locale } from "common/locale";
import { useSelector } from "react-redux";

function Copyright({ locale }) {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://chatserver.com">
        {Locale[locale].t5}
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

export default function AppFooter() {
  const locale = useSelector((state) => state.localer.locale);
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{ justifyContent: "flex-end", height: 120 }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Box component="a" href="https://chatserver.com" sx={iconStyle}>
                  <img
                    src="/static/images/appFooterFacebook.png"
                    alt="Facebook"
                  />
                </Box>
                <Box component="a" href="https://chatserver.com" sx={iconStyle}>
                  <img src="/static/images/appFooterTwitter.png" alt="X" />
                </Box>
              </Grid>
              <Grid item>
                <Copyright locale={locale} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              {Locale[locale].t6}
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/chtsys/terms/">{Locale[locale].t7}</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/chtsys/privacy/">{Locale[locale].t8}</Link>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {Locale[locale].t54}
              <Link
                href="https://www.freepik.com"
                rel="sponsored"
                title="Freepik"
              >
                {Locale[locale].t10}
              </Link>
              {Locale[locale].t55}
              <Link
                href="https://chatserver.com"
                rel="sponsored"
                title="Flaticon"
              >
                https://chatserver.com
              </Link>
              {Locale[locale].t56}
              <Link
                href="https://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
