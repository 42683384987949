function setStorag(key, value) {
  if ("" === value) {
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
  return;
}

function getStorage(key, defaultValue) {
  const value = localStorage.getItem(key);
  if (!value) {
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
}

function delStorage(key) {
  localStorage.removeItem(key);
}

export { setStorag, getStorage, delStorage };
