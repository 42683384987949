import { configureStore } from "@reduxjs/toolkit";
import userReducer from "common/Reduxer/user.reducer";
import localeStore from "common/Reduxer/locale.reducer";
import chaterReducer from "common/Reduxer/chat.reducer";

export default configureStore({
  reducer: {
    user: userReducer,
    localer: localeStore,
    chater: chaterReducer,
  },
});
