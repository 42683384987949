import fetch from "common/Api/fetch";

function OnLogin(body) {
  return fetch({
    url: "/console/login",
    method: "post",
    data: body,
  });
}

function OnCreate(body) {
  return fetch({
    url: "/console/create",
    method: "post",
    data: body,
  });
}

function OnUserInfo() {
  return fetch({
    url: "/auth/user/info",
    method: "get",
    options: {},
  });
}

function OnLogout() {
  return fetch({
    url: "/auth/user/logout",
    method: "get",
  });
}

function OnIsLogin(data) {
  return fetch({
    url: "/auth/user/islogin",
    method: "post",
    data: data,
  });
}

function OnSdkAccess(plt) {
  return fetch({
    url: "/sdk/access?plt=" + plt,
    method: "get",
  });
}

function OnPlatforms() {
  return fetch({
    url: "/platform/all",
    method: "get",
  });
}

function OnAddPlatform(body) {
  return fetch({
    url: "/platform/add",
    method: "post",
    data: body,
  });
}

function OnUpdatePlatform(body) {
  return fetch({
    url: "/platform/update",
    method: "post",
    data: body,
  });
}

function OnSearch(query) {
  return fetch({
    url: "console/search?" + query,
    method: "get",
  });
}

export { OnLogin };
export { OnCreate };
export { OnUserInfo };
export { OnIsLogin };
export { OnLogout };
export { OnSdkAccess };
export { OnPlatforms };
export { OnAddPlatform };
export { OnUpdatePlatform };
export { OnSearch };
