import { createSlice } from "@reduxjs/toolkit";

export const chaterStore = createSlice({
  name: "chater",
  initialState: {
    list: [
      // {
      //   id: 1,
      //   alt: "Reactjs",
      //   title: "Facebook", // username
      //   subtitle: "What are you doing?", // Newest message
      //   date: new Date(),
      //   unread: 0,
      // }
    ],
    current: {
      chat: {},
      message: [
        // {
        //   id: 1,
        //   username: "1234",
        //   unique_id: "1234",
        //   message: `{"message":"How are you?填充相似结果对应的图片具体信息"}`,
        //   source: 0,
        //   site: "",
        //   readed: 0,
        //   create_at: "2024-09-26 09:23:12",
        // }
      ],
      platform: { id: 0 },
      access: "",
    },
    alert: {
      open: false,
      message: "",
    },
    badge: {
      chat: 0,
    },
  },
  reducers: {
    // 设置聊天用户列表
    chatList: (state, action) => {
      let payload = action.payload;
      if (Array.isArray(payload)) {
        let list = [];
        for (var i = 0; i < payload.length; i++) {
          let it = payload[i];
          let message = it["message"];
          if (message && message.length > 0) {
            message = JSON.parse(message)["message"];
          }

          list.push({
            alt: "chat",
            id: it["id"],
            username: it["username"],
            site: it["site"],
            title: it["account"].length !== 0 ? it["account"] : it["username"], // username
            subtitle: message, // Newest message
            date: it["createAt"],
            unread: it["readed"],
            obj: it,
          });
        }

        state.list = list;
      }
    },
    // 新增聊天用户
    addChat: (state, action) => {
      state.list.push({
        alt: "chat",
        id: action.payload["id"],
        username: action.payload["username"],
        site: action.payload["site"],
        title: action.payload["username"], // username
        subtitle: action.payload["message"], // Newest message
        date: action.payload["createAt"],
        unread: action.payload["readed"],
        obj: action.payload,
      });
    },

    // 更新当前打开的聊天用户
    nowChat: (state, action) => {
      state.list.forEach((it) => {
        if (it.username === action.payload.username) {
          it.unread = 0;
        }
      });

      state.current.chat = action.payload;
    },
    // 获取当前的消息
    nowMessage: (state, action) => {
      state.current.message = action.payload;
    },
    // 添加当前新消息
    addMessage: (state, action) => {
      if (state.current.chat.username !== action.payload["userName"]) {
        state.list.forEach((it) => {
          if (it.username === action.payload["userName"]) {
            it.unread += parseInt(action.payload["readed"]);

            let msg = JSON.parse(action.payload["message"]);
            it.subtitle = msg["message"];
            it.date = action.payload["createAt"];
          }
        });
        return;
      }

      state.current.message.push(action.payload);
    },
    nowPlatform: (state, action) => {
      state.current.platform = action.payload;
    },
    alertMessage: (state, action) => {
      state.alert = {
        open: true,
        severity: action.payload.severity,
        message: action.payload.message,
      };
    },
    alertClose: (state, action) => {
      state.alert = {
        open: false,
        message: "",
      };
    },
    setAccess: (state, action) => {
      state.current.access = action.payload;
    },
    badgeChat: (state, action) => {
      state.badge.chat = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  chatList,
  addChat,
  nowChat,
  nowMessage,
  addMessage,
  nowPlatform,
  alertMessage,
  alertClose,
  setAccess,
  badgeChat,
} = chaterStore.actions;

export default chaterStore.reducer;
