import { createSlice } from "@reduxjs/toolkit";
import { getStorage, setStorag } from "common/Storage/storage";

export const localeStore = createSlice({
  name: "localer",
  initialState: {
    locale: getStorage("chat-locale", "en"),
  },
  reducers: {
    onlocale: (state, action) => {
      state.locale = action.payload;
      setStorag("chat-locale", action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { onlocale } = localeStore.actions;

export default localeStore.reducer;
