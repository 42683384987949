import * as React from "react";
import {
  Box,
  ListItemIcon,
  Tooltip,
  IconButton,
  Avatar,
  Divider,
} from "@mui/material";
import Link from "@mui/material/Link";
import AppBar from "components/AppBar";
import Toolbar from "components/Toolbar";
import Logout from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStorag } from "common/Storage/storage";
import { updateState } from "common/Reduxer/user.reducer";
import { languages, Locale } from "common/locale";
import { onlocale } from "common/Reduxer/locale.reducer";

const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
  lineHeight: "32px",
};

function AppAppBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleComein = (event) => {
    setAnchorEl(null);
    navigate("/chat");
  };

  const handleLogout = (event) => {
    setAnchorEl(null);
    dispatch(updateState(false));
    setStorag("account-info", {});
  };

  const loginState = useSelector((state) => state.user.loginState);
  const locale = useSelector((state) => state.localer.locale);

  const [anchorLocale, setAnchorLocale] = React.useState(null);
  const openLocale = Boolean(anchorLocale);
  const handleLocaleClick = (event) => {
    setAnchorLocale(event.currentTarget);
  };
  const handleLocaleClose = () => {
    setAnchorLocale(null);
  };

  const defaultLocale = () => {
    for (let i = 0; i < languages.length; ++i) {
      let it = languages[i];
      if (it.code === locale) {
        return it.name;
      }
    }
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: 24 }}
          >
            {Locale[locale].t1}
          </Link>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Button
              id="fade-button"
              aria-controls={openLocale ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openLocale ? "true" : undefined}
              onClick={handleLocaleClick}
              color="white"
            >
              {defaultLocale()}
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorLocale}
              open={openLocale}
              onClose={handleLocaleClose}
              TransitionComponent={Fade}
            >
              {languages.map((language) => (
                <MenuItem
                  key={language.code}
                  onClick={() => {
                    dispatch(onlocale(language.code));
                    handleLocaleClose();
                  }}
                >
                  {language.name}
                </MenuItem>
              ))}
            </Menu>
            {loginState ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title={Locale[locale].t2}>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 0 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src="/logo320.jpeg"
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleComein}>
                    <ListItemIcon>
                      <img
                        style={{ height: "24px", width: "24px" }}
                        src="/static/images/login-console.png"
                        alt=""
                      />
                    </ListItemIcon>
                    InCome
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Link
                color="inherit"
                variant="body1"
                underline="none"
                href={loginState ? "/chat" : "/chtsys/63782975bc405b4f"}
                sx={rightLink}
              >
                {loginState ? Locale[locale].t3 : Locale[locale].t4}
              </Link>
            )}

            {/* <Link
              variant="h6"
              underline="none"
              href="/chtsys/3474c627769b0ee7"
              sx={{ ...rightLink, color: "secondary.main" }}
            >
              {"Sign Up"}
            </Link> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
