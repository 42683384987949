import React from "react";
import MainPage from "views";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "views/Index/Home";
import SignIn from "views/Index/SignIn";
import SignUp from "views/Index/SignUp";
import ForgotPassword from "views/Index/ForgotPassword";
import Terms from "views/Index/Terms";
import Privacy from "views/Index/Privacy";

import "react-chat-elements/dist/main.css";
import { Provider } from "react-redux";
// https://react-redux.js.org/tutorials/quick-start
import store from "common/Storage/store";
import { useSelector } from "react-redux";

function RequireAuth({ children }) {
  const loginState = useSelector((state) => state.user.loginState);

  return loginState ? children : <Navigate to="/" replace />;
}

function LoginAuth({ children }) {
  const loginState = useSelector((state) => state.user.loginState);

  return loginState ? <Navigate to="/" replace /> : <SignIn />;
}

function SignUpAuth({ children }) {
  const loginState = useSelector((state) => state.user.loginState);

  return loginState ? <Navigate to="/" replace /> : <SignUp />;
}

function App(props) {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/chtsys/63782975bc405b4f/*"
              element={
                <LoginAuth>
                  <SignIn />
                </LoginAuth>
              }
            />
            <Route
              path="/chtsys/3474c627769b0ee7/*"
              element={
                <SignUpAuth>
                  <SignUp />
                </SignUpAuth>
              }
            />
            <Route
              path="/chtsys/forgot-password/*"
              element={<ForgotPassword />}
            />
            <Route path="/chtsys/terms/*" element={<Terms />} />
            <Route path="/chtsys/privacy/*" element={<Privacy />} />

            {/*except path   */}
            <Route
              path="/chat/*"
              element={
                <RequireAuth>
                  <MainPage />
                </RequireAuth>
              }
            />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
