import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "components/Typography";
import { useSelector } from "react-redux";
import { Locale } from "common/locale";

function ProductSmokingHero() {
  const locale = useSelector((state) => state.localer.locale);

  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
      <Button
        sx={{
          border: "4px solid currentColor",
          borderRadius: 0,
          height: "auto",
          py: 2,
          px: 5,
        }}
      >
        <Typography variant="h4" component="span">
          {Locale[locale].t35}
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ my: 3 }}>
        {Locale[locale].t36}
      </Typography>
      <Box
        component="img"
        src="/static/images/productBuoy.svg"
        alt="buoy"
        sx={{ width: 60 }}
      />
    </Container>
  );
}

export default ProductSmokingHero;
