import * as React from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Locale } from "common/locale";

export default function ProductHero() {
  const navigate = useNavigate();
  const locale = useSelector((state) => state.localer.locale);
  const loginState = useSelector((state) => state.user.loginState);
  const onStart = (event) => {
    if (true === loginState) {
      navigate("/chat");

      return;
    }

    navigate("/chtsys/63782975bc405b4f");
    return;
  };
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(/static/images/main-head-bg.jpeg)`,
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      {/* <img
        style={{ display: "none" }}
        src={"/static/images/main-head-bg.jpeg"}
        alt="increase priority"
      /> */}
      <Typography color="inherit" align="center" variant="h2" marked="center">
        {Locale[locale].t26}
      </Typography>
      <Typography
        align="center"
        variant="h5"
        color={"white"}
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        {Locale[locale].t27} <br /> {Locale[locale].t28}
      </Typography>

      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        onClick={onStart}
        sx={{ minWidth: 200 }}
      >
        {Locale[locale].t29}
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        {Locale[locale].t30}
      </Typography>
    </ProductHeroLayout>
  );
}
